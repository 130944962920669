/* You can add global styles to this file, and also import other style files */
@use "@fiscalteam/ngx-nitro-services/theme/default/nitro_component_default_theme";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;

  .p-inplace {

    padding: 0 !important;

    > .p-inplace-display {
      display: inline-block;
      width: 100%;
      border: 1px solid transparent;;
    }

    &:focus {
      outline: 3px solid var(--primary-color);
    }

  }
}
